import * as React from 'react'
import Img, { GatsbyImageFluidProps } from 'gatsby-image'

export const IntroImage = ({ fluid }) => (
  <Img
    fluid={fluid}
    loading={'eager'}
    fadeIn={false}
    style={{
      top: 0,
      left: 0,
      minWidth: '100%',
      height: '110%',
      marginTop: '-5%',
      padding: '8px 0',
      position: 'fixed',
      zIndex: 0,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      marginBottom: '1.45rem',
      flex: 0
    }}
  />
)

export const IntroTemplate: React.FC<{ fluid: GatsbyImageFluidProps }> = ({ children, fluid, ...props }: any) => {
  return (
    <React.Fragment>
      <IntroImage fluid={fluid} />
      {children}
    </React.Fragment>
  )
}
