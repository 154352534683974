import * as React from 'react'
import { graphql } from 'gatsby'
import { IntroImage } from '../components/IntroImage'
import { Nobr } from '@jfa/ui'

const IndexPage: React.FC = () => {
  return (
    <p
      style={{
        color: '#fff',
        fontSize: '26px',
        top: '55%',
        left: 0,
        textAlign: 'right',
        width: '100%',
        position: 'absolute',
        padding: '24px',
        boxSizing: 'border-box',
        fontFamily: 'Playfair Display'
      }}
    >
      <Nobr>Kathrein Allenberg</Nobr> <Nobr>Violine | Barockvioline</Nobr>
      <br />
      <Nobr>Anne-Kathrein Jordan</Nobr> <Nobr>Klavier | Hammerklavier</Nobr>
    </p>
  )
}

export default ({
  data: {
    allFile: {
      edges: [
        {
          node: {
            childImageSharp: { fluid }
          }
        }
      ],
      b
    }
  },
  ...props
}) => (
  <React.Fragment>
    <IntroImage fluid={fluid} />
    <IndexPage />
  </React.Fragment>
)

//withPageTemplate(IndexPage, () => <IntroImage fluid={fluid} />)(props)

export const query = graphql`
  query {
    allFile(filter: { base: { eq: "DKsmaller.jpg" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
